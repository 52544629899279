export function FacebookIcon() {
  return (
    <svg
      className="icon facebook"
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 80 80"
    >
      <path d="M21.2,23h8.1v-4.2c0-4.7,0.5-8.1,1.5-10.4c1-2.3,2.8-4.2,5.5-5.6c2.7-1.4,6-2.2,10.1-2.2c4.2,0,8.2,0.6,12.2,1.9l-2,10.2c-2.3-0.6-4.6-0.8-6.7-0.8c-2.1,0-3.6,0.5-4.6,1.5c-0.9,1-1.4,2.9-1.4,5.7V23h10.9v11.5H44v43.8H29.4V34.5h-8.1V23z"></path>
    </svg>
  );
}

export function InstagramIcon() {
  return (
    <svg
      className="icon instagram"
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 80 80"
    >
      <path d="M40.4,79.6c-3.8,0-7.6,0-11.4,0c-3.7-0.1-7.4-0.1-11.1-0.9C9.1,76.5,3.7,70.9,1.7,62.1c-0.7-3-0.8-6-0.8-9c0-8.7-0.1-17.5,0-26.2c0-3.6,0.2-7.2,1.2-10.7C4.6,8,10,3.2,18.2,1.3c3-0.7,6-0.8,9-0.8c8.8,0,17.5-0.1,26.3,0c3.5,0,7,0.2,10.4,1.2C72.3,4,77.3,9.5,79.1,17.9c0.8,3.5,0.8,7.1,0.8,10.7c0,7.6,0,15.1,0,22.7c0,3.7-0.1,7.5-0.9,11.1c-2.1,8.9-7.7,14.3-16.6,16.3c-3.4,0.8-7,0.8-10.5,0.8C48.1,79.6,44.3,79.6,40.4,79.6z M40.4,7.3c0,0,0,0.1,0,0.1c-3.2,0-6.4,0-9.6,0c-3.6,0.1-7.3,0-10.9,0.7c-5.7,1.1-9.5,4.4-11.1,10.1C8.2,20.8,8,23.4,8,26c-0.1,8.1-0.1,16.3-0.1,24.4c0,3.3,0,6.7,0.7,10c1.2,5.7,4.5,9.5,10.2,11.1c2.4,0.7,4.9,0.8,7.4,0.9c9.5,0,18.9,0.1,28.4,0c2.1,0,4.2-0.2,6.2-0.6c5.8-1.1,9.6-4.4,11.2-10.2c0.7-2.5,0.8-5.1,0.9-7.7c0-9.3,0-18.5,0-27.8c0-2.4-0.2-4.8-0.7-7.1c-0.9-4-3-7.2-6.6-9.2c-2.6-1.5-5.5-2-8.5-2.1C51.5,7.5,45.9,7.5,40.4,7.3z"></path>
      <path d="M40.4,19.7c11.3,0.1,20.4,9.1,20.3,20.5c-0.1,11.3-9.2,20.3-20.5,20.2C29,60.3,20,51.1,20.1,39.8C20.1,28.7,29.3,19.6,40.4,19.7z M40.4,26.7c-7.3,0-13.2,5.9-13.3,13.2c0,7.3,5.9,13.3,13.2,13.3c7.3,0,13.3-5.9,13.3-13.3C53.6,32.7,47.7,26.7,40.4,26.7z"></path>
      <path d="M61.6,23.6c-2.6,0.1-4.8-2-4.8-4.6c-0.1-2.6,2-4.8,4.6-4.9c2.6-0.1,4.8,2,4.9,4.6C66.4,21.4,64.3,23.5,61.6,23.6z"></path>
    </svg>
  );
}

export function YoutubeIcon() {
  return (
    <svg
      className="icon facebook"
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 80 80"
    >
      <path d="M21.2,23h8.1v-4.2c0-4.7,0.5-8.1,1.5-10.4c1-2.3,2.8-4.2,5.5-5.6c2.7-1.4,6-2.2,10.1-2.2c4.2,0,8.2,0.6,12.2,1.9l-2,10.2c-2.3-0.6-4.6-0.8-6.7-0.8c-2.1,0-3.6,0.5-4.6,1.5c-0.9,1-1.4,2.9-1.4,5.7V23h10.9v11.5H44v43.8H29.4V34.5h-8.1V23z"></path>
    </svg>
  );
}

export function LinkedinIcon() {
  return (
    <svg
      className="icon facebook"
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="16"
      viewBox="0 0 80 80"
    >
      <path d="M21.2,23h8.1v-4.2c0-4.7,0.5-8.1,1.5-10.4c1-2.3,2.8-4.2,5.5-5.6c2.7-1.4,6-2.2,10.1-2.2c4.2,0,8.2,0.6,12.2,1.9l-2,10.2c-2.3-0.6-4.6-0.8-6.7-0.8c-2.1,0-3.6,0.5-4.6,1.5c-0.9,1-1.4,2.9-1.4,5.7V23h10.9v11.5H44v43.8H29.4V34.5h-8.1V23z"></path>
    </svg>
  );
}

export function OrkaOOOIcon() {
  return (
    <svg
      className="orka-logo"
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 265.42 66.25"
    >
      <g id="Layer_1-2">
        <g>
          <g>
            <path
              style={{ fill: "#d60000" }}
              d="m9.19,61.5c0,.7-.11,1.34-.33,1.92-.22.58-.53,1.08-.94,1.5-.4.42-.89.75-1.45.98-.57.23-1.19.35-1.88.35s-1.31-.12-1.87-.35c-.56-.23-1.05-.56-1.45-.98-.4-.42-.71-.92-.94-1.5-.22-.58-.33-1.22-.33-1.92s.11-1.34.33-1.92c.22-.58.53-1.09.94-1.51.4-.42.89-.75,1.45-.98.56-.23,1.19-.35,1.87-.35s1.31.12,1.88.35c.57.23,1.05.56,1.45.98.4.42.71.93.94,1.51.22.58.33,1.22.33,1.92Zm-1.29,0c0-.57-.08-1.09-.23-1.54-.16-.45-.38-.84-.66-1.15-.29-.31-.63-.56-1.04-.73-.41-.17-.86-.25-1.37-.25s-.95.08-1.36.25c-.41.17-.75.41-1.04.73-.29.31-.51.7-.67,1.15-.16.46-.23.97-.23,1.54s.08,1.08.23,1.54c.16.45.38.84.67,1.15.29.31.64.55,1.04.72.41.17.86.25,1.36.25s.96-.08,1.37-.25c.41-.17.75-.41,1.04-.72.29-.31.51-.7.66-1.15.16-.45.23-.97.23-1.54Z"
            ></path>
            <path
              style={{ fill: "#d60000" }}
              d="m29.99,61.5c0,.7-.11,1.34-.33,1.92-.22.58-.53,1.08-.94,1.5s-.89.75-1.45.98c-.57.23-1.19.35-1.88.35s-1.31-.12-1.87-.35c-.56-.23-1.05-.56-1.45-.98-.4-.42-.71-.92-.94-1.5-.22-.58-.33-1.22-.33-1.92s.11-1.34.33-1.92c.22-.58.53-1.09.94-1.51.4-.42.89-.75,1.45-.98.56-.23,1.19-.35,1.87-.35s1.31.12,1.88.35c.56.23,1.05.56,1.45.98.4.42.72.93.94,1.51.22.58.33,1.22.33,1.92Zm-1.29,0c0-.57-.08-1.09-.23-1.54-.16-.45-.38-.84-.66-1.15-.29-.31-.63-.56-1.04-.73s-.86-.25-1.36-.25-.95.08-1.36.25-.75.41-1.04.73c-.29.31-.51.7-.67,1.15-.16.46-.23.97-.23,1.54s.08,1.08.23,1.54c.16.45.38.84.67,1.15s.64.55,1.04.72c.41.17.86.25,1.36.25s.96-.08,1.36-.25c.41-.17.75-.41,1.04-.72s.51-.7.66-1.15c.16-.45.23-.97.23-1.54Z"
            ></path>
            <path
              style={{ fill: "#d60000" }}
              d="m50.8,61.5c0,.7-.11,1.34-.33,1.92-.22.58-.53,1.08-.94,1.5-.4.42-.89.75-1.45.98-.57.23-1.19.35-1.88.35s-1.31-.12-1.87-.35c-.56-.23-1.05-.56-1.45-.98-.4-.42-.71-.92-.94-1.5-.22-.58-.33-1.22-.33-1.92s.11-1.34.33-1.92c.22-.58.53-1.09.94-1.51.4-.42.89-.75,1.45-.98.56-.23,1.19-.35,1.87-.35s1.31.12,1.88.35c.57.23,1.05.56,1.45.98.4.42.72.93.94,1.51.22.58.33,1.22.33,1.92Zm-1.29,0c0-.57-.08-1.09-.23-1.54-.16-.45-.38-.84-.66-1.15-.29-.31-.63-.56-1.04-.73-.41-.17-.86-.25-1.36-.25s-.95.08-1.36.25c-.41.17-.76.41-1.04.73-.29.31-.51.7-.67,1.15-.16.46-.23.97-.23,1.54s.08,1.08.23,1.54c.16.45.38.84.67,1.15.29.31.63.55,1.04.72.41.17.86.25,1.36.25s.96-.08,1.36-.25c.41-.17.75-.41,1.04-.72.29-.31.51-.7.66-1.15.16-.45.23-.97.23-1.54Z"
            ></path>
          </g>
          <g id="Layer_2-2">
            <g id="Layer_1-2">
              <polygon points="245.62 14.77 251.43 26.35 258.84 26.35 245.62 0 225.86 39.52 233.22 39.52 245.62 14.77"></polygon>
              <polygon points="258.07 39.57 265.42 39.57 262.13 32.98 254.78 32.98 258.07 39.57"></polygon>
              <polygon points="186.92 39.57 167.16 19.81 186.92 0 177.6 0 157.79 19.81 177.6 39.57 186.92 39.57"></polygon>
              <path d="m25.35,39.52c5.26.07,10.31-2.01,13.99-5.77,3.8-3.62,5.92-8.65,5.86-13.9.12-5.29-2.01-10.39-5.86-14.03C35.68,2.04,30.62-.07,25.35,0c-5.24-.1-10.28,2.01-13.89,5.82-3.81,3.65-5.93,8.71-5.87,13.99-.08,5.26,2.05,10.32,5.87,13.94,3.61,3.79,8.65,5.88,13.89,5.77Zm-9.27-29.01c2.43-2.54,5.8-3.95,9.32-3.92,3.52-.06,6.9,1.36,9.32,3.92,2.52,2.42,3.91,5.77,3.86,9.25.06,3.51-1.34,6.88-3.86,9.32-2.46,2.52-5.85,3.92-9.37,3.86-3.49.06-6.85-1.34-9.27-3.86-2.5-2.45-3.87-5.82-3.8-9.32-.05-3.48,1.32-6.82,3.8-9.25Z"></path>
              <path d="m88.27,6.59h13.23c1.73-.02,3.39.69,4.57,1.96,1.3,1.16,2.04,2.83,2.05,4.57.05,1.8-.7,3.53-2.05,4.73-1.19,1.25-2.84,1.96-4.57,1.95l-5.92-.05,11.47,19.81h7.56l-8.12-14.19c2.36-.96,4.39-2.61,5.81-4.73,1.56-2.17,2.37-4.79,2.31-7.47,0-3.46-1.39-6.78-3.86-9.2-2.4-2.51-5.73-3.91-9.2-3.87h-17.38l3.33,5.82.77.66Z"></path>
              <path
                d="m25.45,24.91c2.86.02,5.19-2.28,5.21-5.14.02-2.86-2.28-5.19-5.14-5.21-1.4,0-2.74.55-3.72,1.54-.99.95-1.53,2.28-1.49,3.65-.04,1.36.5,2.67,1.49,3.61.96.98,2.28,1.54,3.65,1.54Z"
                style={{ fill: "#d60000" }}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function LocationIcon() {
  return (
    <svg
      className="icon location"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
    </svg>
  );
}

export function SuccessIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="64 64 896 896"
      focusable="false"
      data-icon="check-circle"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path>
      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
    </svg>
  );
}

export function ErrorIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" fillOpacity="0.01" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7664 3.98275C13.0093 4.08343 13.2301 4.23099 13.416 4.417L19.584 10.584C19.7702 10.7699 19.9178 10.9907 20.0186 11.2338C20.1194 11.4769 20.1712 11.7374 20.1712 12.0005C20.1712 12.2636 20.1194 12.5241 20.0186 12.7672C19.9178 13.0103 19.7702 13.2311 19.584 13.417L13.416 19.584C13.2301 19.77 13.0093 19.9176 12.7664 20.0183C12.5234 20.1189 12.263 20.1707 12 20.1707C11.737 20.1707 11.4766 20.1189 11.2336 20.0183C10.9907 19.9176 10.7699 19.77 10.584 19.584L4.416 13.417C4.22983 13.2311 4.08214 13.0103 3.98138 12.7672C3.88062 12.5241 3.82875 12.2636 3.82875 12.0005C3.82875 11.7374 3.88062 11.4769 3.98138 11.2338C4.08214 10.9907 4.22983 10.7699 4.416 10.584L10.584 4.417C10.7699 4.23099 10.9907 4.08343 11.2336 3.98275C11.4766 3.88207 11.737 3.83026 12 3.83026C12.263 3.83026 12.5234 3.88207 12.7664 3.98275ZM11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V7.99999C13 7.73478 12.8946 7.48042 12.7071 7.29289C12.5196 7.10535 12.2652 6.99999 12 6.99999C11.7348 6.99999 11.4804 7.10535 11.2929 7.29289C11.1053 7.48042 11 7.73478 11 7.99999V13C11 13.2652 11.1053 13.5196 11.2929 13.7071ZM11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16C13 15.7348 12.8946 15.4804 12.7071 15.2929C12.5196 15.1054 12.2652 15 12 15C11.7348 15 11.4804 15.1054 11.2929 15.2929C11.1053 15.4804 11 15.7348 11 16C11 16.2652 11.1053 16.5196 11.2929 16.7071Z"
        fill="white"
      />
    </svg>
  );
}
