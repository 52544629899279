export const translations = {
  sk: {
    // hero section
    heroSlide1H1: "Servis a tuning odpruženia",
    heroSlide1H2: "Custom paint-jobs and custom-built bikes",
    heroSlide2H1: "Autorizované záručné",
    heroSlide2H2: "a servisné centrum",
    heroSlide2H3: "DVO suspension Slovensko",
    heroSlideButton: "viac informácií",
    // our goal section
    ourGoalH2: "Našim cieľom je",
    ourGoalP:
      "byť originálni v našich projektoch a ukázať zákazníkom že ich bicykle môžu vyzerať úplne inak ako sériové bicykle vybalené zo škatule",
    // our experience section
    ourExperienceH1: "Naše dlhoročné skúsenosti",
    ourExperienceP:
      "z prostredia predaja, pretekania a športového marketingu Vám radi poskytneme v podobe rád a odborných služieb.",
    // our focus section
    ourFocusH1: "Naším hlavným zameraním je servis odpruženia značiek:",
    ourFocusP:
      "Po konzultácii aj ďalších výrobcov. Servisu kompletných bicyklov sa venujeme už iba okrajovo - mimo sezónu.",
    // service section
    serviceH1: "Servis odpruženia a služby",
    serviceP1:
      "Servis odpruženia je jednou z našich priorít. Poskytujeme servis vidlic a tlmičov značiek Ohlins, DVO Rockshox a Fox, Formula, po konzultácii poskytujeme servis na ďalšie renomované značky.",
    serviceP2:
      "Vykonávame aj servis teleskopických sedloviek, aktuálne predovšetkým značky Rockshox, E13, SDG a PRO, po konzultácii aj iné.",
    serviceItem1H: "Setup",
    serviceItem1P:
      "Vďaka našim dlhoročným skúsenostiam Vám dokážeme nastaviť odpruženie presne podľa Vašich predstáv.",
    serviceItem2H: "Parts",
    serviceItem2P:
      "Dodávame všetky diely, tesnenia, náradie a ďalšie potrebné príslušenstvo pre zákazníkov alebo servisy.",
    serviceItem3H: "Custom build",
    serviceItem3P:
      "Ponúkame zmeny sériového bicykla podľa požiadaviek zákazníka. Vaše predstavy jedinečného bicykla premeníme na skutočnosť.",
    serviceItem4H: "Upgrades",
    serviceItem4P:
      "Iba servisom to u nás nekončí, na tlmiče aj vidlice dodávame a montujeme tesniace sady a rôzne upgrady.",
    serviceItem5H: "Professional",
    serviceItem5P:
      "Pri servise odpruženia dbáme na odborne vykonanú prácu. Na servis vidlíc a tlmičov používame vákuové a dusíkové zariadenia.",
    serviceItem6H: "Paint airbrush",
    serviceItem6P:
      "Dizajn rámov, prilieb, športového vybavenia a všetkého čo si budete želať.",
    // contact section
    contactH1: "Kontakt",
    contactFormName: "Meno",
    contactFormEmailOrPhone: "Email alebo telefón",
    contactFormMessage: "Vaša správa",
    contactFormButton: "Odoslať",
    contactFormErrorMessage: "Správu sa nepodarilo odoslať.",
    contactFormNotFilledMessage: "Niektoré polia neboli správne vyplnené.",
    contactFormSuccessMessage: "Vaša správa bola úspešne odoslaná.",
  },
  en: {
    // hero section
    heroSlide1H1: "Suspension servicing and tuning",
    heroSlide1H2: "Custom paint-jobs and custom-built bikes",
    heroSlide2H1: "Authorized",
    heroSlide2H2: "service center",
    heroSlide2H3: "DVO suspension Slovakia",
    heroSlideButton: "more information",
    // our goal section
    ourGoalH2: "Our goal is",
    ourGoalP:
      "to be original in our projects and to show our customers that their bikes can look completely different from the stock bikes out of the box",
    // our experience section
    ourExperienceH1: "Years of experience",
    ourExperienceP:
      // "in sales, racing and sports marketing will be happy to provide you with advice and expert service.",
      "Sales? Racing? Sports marketing? Yes - our professional team will help with it!",
    // our focus section
    ourFocusH1:
      "Our main focus is servicing the suspensions of the following brands:",
    ourFocusP:
      "Other manufacturers after consultation. We only service complete bikes on a marginal basis - in the off-season.",
    // service section
    serviceH1: "Suspension servicing and services",
    serviceP1:
      "Suspension service is one of our priorities. We service Ohlins, DVO Rockshox and Fox, Formula forks and shocks, and we service other reputable brands after consultation.",
    serviceP2:
      "We also service telescopic seatposts, currently mainly Rockshox, E13, SDG and PRO brands, others after consultation.",
    serviceItem1H: "Setup",
    serviceItem1P:
      "Thanks to our many years of experience, we can set up your suspension exactly to your liking.",
    serviceItem2H: "Parts",
    serviceItem2P:
      "We supply all the parts, seals, tools and other necessary accessories for customers or workshops.",
    serviceItem3H: "Custom build",
    serviceItem3P:
      "We offer modifications to the production bike according to the customer's requirements. We turn your ideas of a unique bike into reality.",
    serviceItem4H: "Upgrades",
    serviceItem4P:
      "We don't stop with just servicing, we supply and fit seal kits and various upgrades to both shocks and forks.",
    serviceItem5H: "Professional",
    serviceItem5P:
      "When it comes to suspension servicing, we make sure the job is done professionally. We use vacuum and nitrogen equipment to service forks and shocks.",
    serviceItem6H: "Paint airbrush",
    serviceItem6P:
      "Design frames, helmets, sports equipment and anything else you could want.",
    // contact section
    contactH1: "Contact",
    contactFormName: "Name",
    contactFormEmailOrPhone: "Email or phone",
    contactFormMessage: "Your message",
    contactFormButton: "Send",
    contactFormErrorMessage: "Failed to send message.",
    contactFormNotFilledMessage: "Some fields were not filled correctly.",
    contactFormSuccessMessage: "Message successfully sent.",
  },
};
