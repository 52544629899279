import { isInViewport } from "./Layout/utils";

function isBellowViewport(element) {
  if (element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top > window.innerHeight &&
      rect.top > document.documentElement.clientHeight
    );
  } else {
    return false;
  }
}

let timeoutIDs = [];

/**
 * Animate elements
 */
export function animateElements(isMobile) {
  // intro
  const heroSection = document.querySelector(".hero");
  const heroHeadingsH1 = heroSection.querySelector(".headings h1");
  const heroHeadingsH2 = heroSection.querySelector(".headings h2");
  const heroButton = heroSection.querySelector(".hero-button-wrapper");
  const heroBannersLeft = heroSection.querySelector(
    ".hero-banners-banner-left"
  );
  const heroBannersRight = heroSection.querySelector(
    ".hero-banners-banner-right"
  );

  if (isInViewport(heroHeadingsH1) /*&& !animatedSections.includes("hero") */) {
    heroHeadingsH1.classList.add("anim_hero-headings-h1");
    heroHeadingsH1.style.cssText = `animation-delay: 700ms;`;
    heroHeadingsH2.classList.add("anim_hero-headings-h2");
    heroHeadingsH2.style.cssText = "animation-delay: 900ms;";
    heroButton.classList.add("anim_hero-button");
    heroButton.style.cssText = "animation-delay: 1150ms;";
    heroBannersLeft.classList.add("anim_fadeIn");
    heroBannersLeft.style.cssText = "animation-delay: 600ms;";
    heroBannersRight.classList.add("anim_fadeIn");
    heroBannersRight.style.cssText = "animation-delay: 600ms;";
  }

  //our goal
  const ourGoalSection = document.querySelector(".our-goal");
  const ourGoalHeading = ourGoalSection.querySelector("h2");
  const ourGoalParagraph = ourGoalSection.querySelector("p");
  const ourGoalStarsBottom = ourGoalSection.querySelector(".second img");
  if (isInViewport(ourGoalHeading)) {
    ourGoalHeading.classList.add("anim_fadeInBottom");
    ourGoalHeading.style.cssText = "animation-duration: 800ms;";
  } /*else if (isBellowViewport(ourGoalHeading)) {
    ourGoalHeading.classList.remove("anim_fadeInBottom");
    ourGoalHeading.style.cssText = "";
  } */
  if (isInViewport(ourGoalParagraph)) {
    ourGoalParagraph.classList.add("anim_fadeInBottom");
    ourGoalParagraph.style.cssText =
      "animation-duration: 1s; animation-delay: 100ms;";
  } /*else if (isBellowViewport(ourGoalParagraph)) {
    ourGoalParagraph.classList.remove("anim_fadeInBottom");
    ourGoalParagraph.style.cssText = "";
  } */
  if (isInViewport(ourGoalStarsBottom)) {
    ourGoalStarsBottom.classList.add("anim_fadeInBottom");
    ourGoalStarsBottom.style.cssText =
      "animation-duration: 800ms; animation-delay: 200ms;";
  } /*else if (isBellowViewport(ourGoalStarsBottom)) {
    ourGoalStarsBottom.classList.remove("anim_fadeInBottom");
    ourGoalStarsBottom.style.cssText = "";
  } */

  //our experience
  const ourExperienceSection = document.querySelector(".our-experience");
  const ourExperienceHeading = ourExperienceSection.querySelector("h3");
  const ourExperienceParagraph = ourExperienceSection.querySelector("p");
  if (isInViewport(ourExperienceHeading)) {
    ourExperienceHeading.classList.add("anim_fadeInLeft");
    ourExperienceHeading.style.cssText = "animation-duration: 800ms";
  } /*else {
    ourExperienceHeading.classList.remove("anim_fadeInLeft");
  }*/
  if (isInViewport(ourExperienceParagraph)) {
    ourExperienceParagraph.classList.add("anim_fadeInBottom");
    ourExperienceParagraph.style.cssText = "animation-duration: 800ms";
  } /*else {
    ourExperienceParagraph.classList.remove("anim_fadeInBottom");
  }*/

  //our focus
  const ourFocusSection = document.querySelector(".our-focus");
  const ourFocusHeading = ourFocusSection.querySelector("h3");
  const ourFocusLogo = ourFocusSection.querySelector(".focus-logo");
  const ourFocusStars = ourFocusSection.querySelectorAll(".focus-star");
  const ourFocusItems = ourFocusSection.querySelectorAll(".focus-item");
  const ourFocusParagraph = ourFocusSection.querySelector("p");
  if (isInViewport(ourFocusHeading)) {
    ourFocusHeading.classList.add("anim_fadeInBottom");
    ourFocusHeading.style.cssText =
      "animation-duration: 800ms; animation-delay: 50ms";
  } /*else {
    ourFocusHeading.classList.remove("anim_fadeInBottom");
    // ourFocusHeading.style.cssText = "";
  }*/
  if (isInViewport(ourFocusLogo)) {
    ourFocusLogo.classList.add("anim_fadeInBottom");
    ourFocusLogo.style.cssText =
      "animation-duration: 600ms; animation-delay: 200ms";
  } /*else if (isBellowViewport(ourFocusLogo)) {
    ourFocusLogo.classList.remove("anim_fadeInBottom");
    //  ourFocusLogo.style.cssText = "";
  }*/
  if (
    ourFocusStars &&
    ourFocusStars.length > 0 &&
    isInViewport(ourFocusStars[0])
  ) {
    ourFocusStars.forEach((item, index) => {
      timeoutIDs[index] = setTimeout(() => {
        item.classList.add("animate");
      }, index * 150 + 400);
    });
  } /*else if (
    ourFocusStars &&
    ourFocusStars.length > 0 &&
    isBellowViewport(ourFocusStars[0])
  ) {
    ourFocusStars.forEach((item, index) => {
      clearTimeout(timeoutIDs[index]);
      item.classList.remove("animate");
    });
  }*/
  if (
    ourFocusItems &&
    ourFocusItems.length > 0 &&
    isInViewport(ourFocusItems[0])
  ) {
    ourFocusItems.forEach((item, index) => {
      item.classList.add("anim_fadeIn");
      item.style.cssText = `animation-delay: ${index * 150 + 150}ms;`;
    });
  } /*else if (
    ourFocusItems &&
    ourFocusItems.length > 0 &&
    isBellowViewport(ourFocusItems[0])
  ) {
    ourFocusItems.forEach((item, index) => {
      item.classList.remove("anim_fadeIn");
      item.style.cssText = "";
    });
  }*/
  if (isInViewport(ourFocusParagraph)) {
    ourFocusParagraph.classList.add("anim_fadeInBottom");
    ourFocusParagraph.style.cssText = "animation-duration: 600ms";
  } /*else if (isBellowViewport(ourFocusParagraph)) {
    ourFocusParagraph.classList.remove("anim_fadeInBottom");
    ourFocusParagraph.style.cssText = "";
  }*/

  // service
  const serviceSection = document.querySelector(".service");
  const serviceHeading = serviceSection.querySelector(".top-heading");
  const serviceParagraph = serviceSection.querySelector(".top-paragraph");
  const serviceJogiWrapper = serviceSection.querySelector(
    ".service-content-top-right"
  );
  const serviceJogi = serviceSection.querySelector(
    ".service-content-top-right-image"
  );
  const serviceSquares = serviceSection.querySelectorAll(".square-container");
  const serviceJogiBck = Array.from(
    serviceSection.querySelectorAll(".service-content-top-right-image-bck")
  );
  const serviceJogiBckBlust = Array.from(
    serviceSection.querySelectorAll(
      ".service-content-top-right-image-bck-blust"
    )
  );

  if (isInViewport(serviceHeading)) {
    serviceHeading.classList.add("anim_fadeInLeft");
    serviceHeading.style.cssText = `animation-duration: 600ms;`;
    serviceParagraph.classList.add("anim_fadeInBottom");
    serviceParagraph.style.cssText = `animation-duration: 700ms;`;
  } /*else if (isBellowViewport(serviceHeading)) {
    serviceHeading.classList.remove("anim_fadeInLeft");
    serviceParagraph.classList.remove("anim_fadeInBottom");
  } */
  if (isInViewport(serviceJogiWrapper)) {
    serviceJogi.classList.add("service-content-top-right-image-anim");
    for (let el of serviceJogiBckBlust) {
      el.classList.add("service-content-top-right-image-bck-blust-anim");
      el.style.cssText = "animation-delay: 700ms";
    }
    for (let el of serviceJogiBck) {
      el.classList.add("service-content-top-right-image-bck-anim");
      el.style.cssText = "animation-delay: 770ms";
    }
  } else {
    serviceJogi.classList.remove("service-content-top-right-image-anim");
    for (let el of serviceJogiBckBlust) {
      el.classList.remove("service-content-top-right-image-bck-blust-anim");
    }
    for (let el of serviceJogiBck) {
      el.classList.remove("service-content-top-right-image-bck-anim");
    }
  }

  if (
    serviceSquares &&
    serviceSquares.length > 0 &&
    isInViewport(serviceSquares[0])
  ) {
    serviceSquares.forEach((item, index) => {
      item.classList.add("anim_fadeIn");
      item.style.cssText = `animation-delay: ${index * 180 + 50}ms;`;
    });
  } /*else if (
    serviceSquares &&
    serviceSquares.length > 0 &&
    isBellowViewport(serviceSquares[0])
  ) {
    serviceSquares.forEach((item) => {
      item.classList.remove("anim_fadeIn");
      item.style.cssText = "";
    });
  } */

  //contact
  const contactSection = document.querySelector(".contact");
  const contactHeading = contactSection.querySelector(
    ".contact-content-heading"
  );
  const contactForm = contactSection.querySelector(".contact-content-form");
  const contactLogo = contactSection.querySelector(".contact-content-logo");
  const contactJogi = contactSection.querySelector(".contact-content-jogi");

  if (isInViewport(contactHeading)) {
    contactHeading.classList.add("anim_fadeInTop");
    contactHeading.style.cssText = "animation-duration: 800ms";
    contactForm.classList.add("anim_fadeInBottom");
    contactForm.style.cssText = "animation-duration: 800ms";
  } /*else {
    contactHeading.classList.remove("anim_fadeInTop");
    contactForm.classList.remove("anim_fadeInBottom");
  }*/
  if (isInViewport(contactLogo)) {
    contactLogo.classList.add("anim_contactLogo");
  } /*else {
    contactLogo.classList.remove("anim_contactLogo");
  } */

  if (isInViewport(contactJogi)) {
    contactJogi.classList.add("anim_contact-jogi");
    contactJogi.style.cssText = "animation-delay: 500ms";
  } else {
    contactJogi.classList.remove("anim_contact-jogi");
  }

  // footer
  const footerSection = document.querySelector(".footer");
  const footerHeading = footerSection.querySelector("h1");
  const footerContent = footerSection.querySelector(".content");
  const footerIcons = footerSection.querySelector(".icons");
  const footerLogo = footerSection.querySelector(".orka-logo-wrapper");

  if (isInViewport(footerHeading)) {
    footerHeading.classList.add("anim_fadeInTop");
    footerHeading.style.cssText = "animation-duration: 800ms";
  } /*else {
    footerHeading.classList.remove("anim_fadeInTop");
  } */

  if (isInViewport(footerContent)) {
    footerContent.classList.add("anim_fadeInBottom");
  } /*else {
    footerContent.classList.remove("anim_fadeInBottom");
  } */

  if (isInViewport(footerIcons)) {
    footerIcons.classList.add("anim_fadeInBottom");
    footerIcons.style.cssText = "animation-duration: 800ms;";
  } /*else {
    footerIcons.classList.remove("anim_fadeInBottom");
  } */

  if (isInViewport(footerLogo)) {
    footerLogo.classList.add("anim_fadeIn");
    footerLogo.style.cssText = "animation-duration: 1000ms;";
  } /*else {
    footerLogo.classList.remove("anim_fadeIn");
  }*/
}
