/**
 * Detect if elements if in viewport
 *
 * @param {domNode} el
 * @returns
 */
/*export function isInViewport(elem, offset = 0.2) {
  if (!elem || offset >= 1) return false;
  let x = elem.getBoundingClientRect().left;
  let y = elem.getBoundingClientRect().top;
  let ww = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  let hw = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  let w = elem.clientWidth;
  let h = elem.clientHeight;
  return y < hw - offset * hw && y + h > 0 && x < ww && x + w > 0;
}
 */
/**
 * Detect if elements if in viewport
 *
 * @param {domNode} el
 * @returns
 */
export function isInViewport(elem) {
  if (!elem) return false;
  let x = elem.getBoundingClientRect().left;
  let y = elem.getBoundingClientRect().top;
  let ww = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );
  let hw = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  let w = elem.clientWidth;
  let h = elem.clientHeight;
  return y < hw && y + h > 0 && x < ww && x + w > 0;
}

/**
 * Check if string is json
 *
 * @param {string} str
 * @returns
 */
export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
