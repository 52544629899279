import { FacebookIcon, InstagramIcon, LocationIcon } from "../icons";
function LinksIconComponent({ links }) {
  return (
    <div className="links-icon">
      <a href={links.facebook || ""} target="blank">
        <FacebookIcon />
      </a>
      <img src="./images/star_gold.png" alt="star" loading="lazy" />
      <a href={links.instagram || ""} target="blank">
        <InstagramIcon />
      </a>
      <img src="./images/star_gold.png" alt="star" loading="lazy" />
      <a href={links.googleMaps || ""} target="blank">
        <LocationIcon />
      </a>
    </div>
  );
}

export default LinksIconComponent;
