import "./Layout/css/main.css";
import React, {
  useEffect,
  useRef,
  useState,
  useLayoutEffect,
  useCallback,
} from "react";
import axios from "axios";
// detect device
import {
  withOrientationChange,
  isMobileOnly,
  isMobile,
} from "react-device-detect";
// utils
import { isInViewport, isJsonString } from "./Layout/utils";
// icons
import {
  FacebookIcon,
  InstagramIcon,
  LocationIcon,
  OrkaOOOIcon,
  SuccessIcon,
  ErrorIcon,
} from "./Layout/icons";
import { translations as _translations } from "./Layout/translations";
// helmet
import { Helmet } from "react-helmet";
//react-scroll
import { Link } from "react-scroll";
//react-parallax
import { Parallax } from "react-parallax";
//lodash
import { debounce } from "lodash";
//antd
import { Input, Switch } from "antd";
// validation
import { isEmail, isMobilePhone } from "validator";
// swiper
import "swiper/css";
import { animateElements } from "./AnimateElements";
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
import TextArea from "antd/es/input/TextArea";
import LinksIconComponent from "./Layout/components/LinksIconComponent";
// register Swiper custom elements
register();

function App() {
  const facebookLink = "https://www.facebook.com/allstarscust/about/";
  const instagramLink = "https://www.instagram.com/allstarscustoms/";
  const googleMapsLink =
    "https://www.google.com/maps/place/Allstars+Customs/@48.7228982,21.2278512,15z/data=!4m6!3m5!1s0x473ee105fbec41a9:0x9eca7a9974533be4!8m2!3d48.7228982!4d21.2278512!16s%2Fg%2F11j2xydl6l";
  // swiper
  const swiperRef = useRef("");
  // lang
  const appLangs = ["en", "sk"];
  const [defaultLang, setDefaultLang] = useState("en");
  const [lang, setLang] = useState("en");
  const [translations, setTranslations] = useState(_translations[lang]);
  // loading
  const loadingTimeoutRef = useRef("");
  const loadingTimeoutAnimRef = useRef("");
  // cache saved
  const [cacheSaved, setCacheSaved] = useState(false);
  // layout refs
  const heroLogoRef = useRef("");
  const heroH1Ref = useRef("");
  const heroH2Ref = useRef("");
  const heroButtonRef = useRef("");
  const heroIconRef = useRef("");
  // sections
  const renderedSections = useRef([]);
  const [_renderedSections, _setRenderedSections] = useState([]);
  // Service section
  const [serviceSectionBckImages] = useState([
    "setup",
    "parts",
    "custom",
    "upgrades",
    "professional",
    "paint",
  ]);
  //
  const delayedAnimateElements = useCallback(debounce(animateElements, 25), []);
  const [activeSlide, setActiveSlide] = useState(0);
  const [scrollDuration] = useState(1000);
  const [loading, setLoading] = useState(true); // true
  const [recentTimeoutData, setRecentTimeoutData] = useState({
    timeoutID: null,
    timeoutTarget: null,
  });
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [logoTransition, setLogoTransition] = useState("");
  const [preloadImages] = useState([
    //"/images/hero_01.jpg",
    "./images/hero_03_jogi.png",
    //"/images/hero_03.jpg",
    "./images/hero_02_arrows.png",
    "./images/hero_02_slogan.png",
    "./images/hero_02_left.jpg",
  ]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [renderSlider, setRenderSlider] = useState(false);
  const heroSection = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    emailOrPhone: "",
    message: "",
  });
  const [formState, _setFormState] = useState({
    submitted: false,
    error: false,
    message: "",
    fields: [],
  });
  const formStateRef = useRef({
    submitted: false,
    error: false,
    message: "",
    fields: [],
  });
  const formTimer = useRef("");
  //const [formSubmitted, setFormSubmitted] = useState(false);
  //const [formResponse, setFormResponse] = useState("");

  /**
   *  our focus - toggling on-click-effect class on clicked elements for touch devices
   * @param {*} e event
   */
  function toggleOnClickEffect(e) {
    if (recentTimeoutData.timeoutID != null) {
      clearTimeout(recentTimeoutData.timeoutID);
    }
    if (
      isTouchDevice &&
      e.currentTarget.classList.contains("on-click-effect")
    ) {
      e.currentTarget.classList.remove("on-click-effect");
      setRecentTimeoutData({
        timeoutID: null,
        timeoutTarget: e.currentTarget,
      });
    } else if (isTouchDevice) {
      if (recentTimeoutData.timeoutTarget != null) {
        removeOnClickEffect(recentTimeoutData.timeoutTarget);
      }
      e.currentTarget.classList.add("on-click-effect");
      const timeoutID = setTimeout(removeOnClickEffect, 2000, e.currentTarget);
      setRecentTimeoutData({
        timeoutID: timeoutID,
        timeoutTarget: e.currentTarget,
      });
    }
  }

  /**
   * Init swiper
   */
  function initSwiper(autoplay = false) {
    const swiperContainer = swiperRef.current;
    const params = {
      // navigation: true,
      loop: true,
      speed: 500,
      autoplay: { delay: 4000 },
      pagination: { clickable: true },
      preventInteractionOnTransition: true,
      injectStyles: [
        `
          .swiper-pagination {
            bottom: 18% !important;
          }
          .swiper-pagination-bullet{
            width: 70px;
            height: 4px;
            border-radius: 0;
            background-color: dimgray;
            padding: 10px 5px;
            margin: 0 !important;
            background-clip: content-box;
            opacity: 0.5;
          }
          .swiper-pagination span:nth-child(1).swiper-pagination-bullet-active {
            background-color: #c59705;
            opacity: 1;
          }
          .swiper-pagination span:nth-child(2).swiper-pagination-bullet-active {
            background-color: #96bd0d;
            opacity: 1;
          } 
          .swiper-pagination span:nth-child(3).swiper-pagination-bullet-active {
            background-color: red;
            opacity: 1;
          } 
      `,
      ],
    };

    swiperContainer.addEventListener("slidechange", (e) => {
      const activeSlide = e.detail[0].realIndex;
      setActiveSlide(activeSlide);
      // preserve animations on first slide
      const slide1 = document.querySelector(".hero-slider .slide-1");
      const slide1H1 = slide1.querySelector(".headings h1");
      const slide1H2 = slide1.querySelector(".headings h2");
      const slide1Btn = slide1.querySelector("button");
      const slide1Icon = slide1.querySelector(".hero-icon");
      slide1H1.classList.add("cancel-anim");
      slide1H2.classList.add("cancel-anim");
      slide1Btn.classList.add("cancel-anim");
      slide1Icon.classList.add("hero-icon-cancel-init-anim");
    });

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
    // play
    /*if (autoplay) {
      swiperContainer.swiper.params.autoplay.delay = 10000;
      swiperContainer.swiper.autoplay.start();
    } */
  }

  /**
   * our focus - removing touch/"hover" effect
   * @param {element} currentTarget
   */
  function removeOnClickEffect(currentTarget) {
    if (isTouchDevice && currentTarget.classList.contains("on-click-effect")) {
      currentTarget.classList.remove("on-click-effect");
    }
  }

  /**
   * Detecting if device has touch functionality
   */
  function detectTouchDevice() {
    try {
      document.createEvent("TouchEvent");
      setIsTouchDevice(true);
    } catch (e) {
      setIsTouchDevice(false);
    }
  }

  function preloadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
    });
  }

  useEffect(() => {
    window.history.scrollRestoration = "manual"; // manual
    (async () => {
      setLogoTransition("");
      setLoading(true); // true
      // simulate loading bar
      setLoadingProgress(1);

      // set lang
      const _lang =
        localStorage.getItem("lang") &&
        isJsonString(localStorage.getItem("lang"))
          ? JSON.parse(localStorage.getItem("lang"))
          : "";
      if (_lang && appLangs.includes(lang)) {
        setDefaultLang(_lang);
        setLang(_lang);
      }

      // save state cache saved
      const cacheSaved =
        localStorage.getItem("cacheSaved") &&
        isJsonString(localStorage.getItem("cacheSaved"))
          ? JSON.parse(localStorage.getItem("cacheSaved"))
          : "";
      if (cacheSaved) {
        setCacheSaved(true);
      } else {
        localStorage.setItem("cacheSaved", JSON.stringify(true));
      }

      // cancel loading with timeout
      loadingTimeoutRef.current = setTimeout(
        async () => {
          setLoading(false);
          // set slider background images
          setSliderBck();
          setLogoTransition("fast-transitions");
          // preload images
          for (let img of preloadImages) {
            const res = await preloadImage(img);
          }
          clearTimeout(loadingTimeoutRef.current);
        },
        cacheSaved ? 1400 : 2800
      );
    })();

    // init swiper
    //  initSwiper();

    // detect touch device
    detectTouchDevice();

    // listeners
    document.addEventListener("scroll", () => {
      delayedAnimateElements(isMobile);
      loadImagesForSection("services");
      loadImagesForSection("contact");
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      // animate elements
      delayedAnimateElements(isMobile);

      // set rendered sections
      setRenderedSections([...renderedSections.current, "hero"]);

      // init swiper
      initSwiper(true);
    }
  }, [loading]);

  useLayoutEffect(() => {
    if (loading) {
      document.body.style.position = "fixed";
      document.body.style.overflowY = "scroll";
      document.body.style.height = "100%";
    }
    if (!loading) {
      loadingTimeoutAnimRef.current = setTimeout(() => {
        document.body.style.position = "sticky";
        document.body.style.overflow = "auto";
        document.body.style.height = "auto";
        clearTimeout(loadingTimeoutAnimRef.current);
      }, 300);
    }
  }, [loading]);

  useEffect(() => {
    setTranslations(_translations[lang]);
  }, lang);

  function setRenderedSections(val) {
    _setRenderedSections(val);
    renderedSections.current = val;
  }

  /**
   * Set slider background images ( performance stuff )
   */
  function setSliderBck() {
    document.querySelector(
      ".hero-slider .slide-1"
    ).style.cssText = `background-image: url("./images/hero_01.jpg");`;
    document.querySelector(
      ".hero-slider .slide-2"
    ).style.cssText = `background-image: url("./images/hero_03.jpg");`;
  }
  /**
   * Load images for section ( performance stuff )
   * @param {string} section
   */
  const loadImagesForSection = (section) => {
    let offset = window.pageYOffset;
    switch (section) {
      case "services":
        const ourExperienceSec = document.querySelector(".our-experience");
        const ourExperienceSecTop = ourExperienceSec.offsetTop;
        if (
          (isInViewport(ourExperienceSec) || ourExperienceSecTop <= offset) &&
          !renderedSections.current.includes(section)
        ) {
          for (let sec of serviceSectionBckImages) {
            document
              .querySelector(`.service-content-squares .${sec}`)
              .classList.add(`${sec}-bck`);
            document.querySelector(
              `.service-content-squares .${sec} .square-content`
            );
            // .classList.add("square-content-bck");
          }
          setRenderedSections([...renderedSections.current, section]);
        }
        break;
      case "contact":
        const ourFocusSec = document.querySelector(".our-experience");
        const ourFocusSecTop = ourFocusSec.offsetTop;
        if (
          (isInViewport(ourFocusSec) || ourFocusSecTop <= offset) &&
          !renderedSections.current.includes(section)
        ) {
          document.querySelector(`.${section}`).classList.add(`${section}-bck`);
          setRenderedSections([...renderedSections.current, section]);
        }
        break;
    }
  };

  function setFormState(state) {
    _setFormState(state);
    formStateRef.current = state;
    clearTimeout(formTimer.current);
    if (state.message) {
      formTimer.current = setTimeout(() => {
        _setFormState({
          ...formStateRef.current,
          error: false,
          submitted: false,
          message: "",
          fields: state.error ? [] : formStateRef.current.fields,
        });
        clearTimeout(formTimer.current);
      }, 8000);
    }
  }

  const handleChange = (key, value) => {
    setFormData((state) => {
      return { ...state, [key]: value };
    });
    // remove input error
    setFormState({
      ...formState,
      fields: formState.fields.filter((item) => item !== key),
    });
  };

  const validateForm = () => {
    let error = false;
    let fields = [];
    if (formData.name.length === 0) {
      fields.push("name");
      error = true;
    } else {
      fields.filter((i) => i !== "emanamelOrPhone");
    }
    if (formData.emailOrPhone.length === 0) {
      fields.push("emailOrPhone");
      error = true;
    } else {
      fields.filter((i) => i !== "emailOrPhone");
    }

    if (formData.emailOrPhone.length !== 0) {
      // is phone number
      if (formData.emailOrPhone.replace("+", "").match(/^[0-9]+$/)) {
        if (!isMobilePhone(formData.emailOrPhone)) {
          fields.push("emailOrPhone");
          error = true;
        }
      }
      // is email
      else {
        if (!isEmail(formData.emailOrPhone)) {
          fields.push("emailOrPhone");
          error = true;
        }
      }
    }

    if (formData.message.length === 0) {
      fields.push("message");
      error = true;
    } else {
      fields.filter((i) => i !== "message");
    }
    if (error) {
      setFormState({
        ...formState,
        error: error,
        submitted: false,
        message: translations.contactFormNotFilledMessage,
        fields: fields,
      });
    }

    return error;
  };

  const submitForm = async () => {
    // reset state of form
    setFormState({
      submitted: false,
      error: false,
      message: "test",
      fields: [],
    });

    // validation
    if (!validateForm()) {
      try {
        const res = await axios({
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          url: `sendMail.php`,
          data: {
            ...formData,
          },
        });
        if (res.data.result === 1) {
          // reset form
          setFormData({ name: "", emailOrPhone: "", message: "" });

          // show notification
          setFormState({
            ...formState,
            submitted: true,
            error: false,
            message: translations.contactFormSuccessMessage,
          });
        } else {
          throw new Error("Error occured");
        }
      } catch (e) {
        setFormState({
          ...formState,
          error: true,
          submitted: false,
          message: translations.contactFormErrorMessage,
        });
      }
    }
  };

  const preserveAnimation = (el, animationClass) => {
    let res = "";
    if (el.current) {
      if (
        el.current.classList &&
        Array.from(el.current.classList).includes(animationClass)
      ) {
        res = animationClass;
      }
    } else if (el) {
      if (el.classList && Array.from(el.classList).includes(animationClass)) {
        res = animationClass;
      }
    }
    return res;
  };

  const handleChangeLang = (checked) => {
    let newLang = defaultLang;
    if (checked) {
      newLang = appLangs.find((i) => i !== lang);
    }
    setLang(newLang);
    localStorage.setItem("lang", JSON.stringify(newLang));
  };

  return (
    <React.Fragment>
      <Helmet>
        <title lang="sk">
          Allstars Customs Košice - Servis a tuning odpruženia
        </title>
        <title lang="en">
          Allstars Customs Košice - Bike suspension service and tuning
        </title>
      </Helmet>
      <div className="app">
        <main>
          {/* LOADER */}
          <div
            className="loader"
            style={
              loading
                ? { transform: "translateY(0)" }
                : { transform: "translateY(-100%)" }
            }
          >
            <div className="loader-inner">
              <div
                className={`loader-icon ${
                  loading ? "loader-icon-active" : "loader-icon-inactive"
                }`}
              >
                <img
                  src="./images/asc_rounded.png"
                  alt=""
                  // ref={rotatingLogo}
                />
              </div>
              <div
                className={`loader-bar-container ${!loading ? "hidden" : ""}`}
              >
                <div
                  className={`loader-bar ${
                    cacheSaved ? "loader-bar-fast" : ""
                  }`}
                  style={{
                    transform: `scaleX(${loadingProgress})`,
                  }}
                ></div>
              </div>
            </div>
          </div>

          {/* SECTION HERO */}
          <section className="section hero" ref={heroSection}>
            <div className="hero-banners">
              <div
                ref={heroLogoRef}
                className={`hero-banners-banner-left logo-bck-${activeSlide} ${preserveAnimation(
                  heroLogoRef,
                  "anim_fadeIn"
                )}`}
              >
                <img
                  className="hero-banners-banner-left-logo"
                  src="./images/Allstars_Customs_banner_Logo.png"
                  alt="allstars logo"
                  loading="lazy"
                />
              </div>

              <div className="hero-banners-banner-right">
                <a
                  href={facebookLink}
                  className="hero-banners-banner-right-icon"
                  target="blank"
                >
                  <FacebookIcon />
                </a>
                <a
                  href={instagramLink}
                  className="hero-banners-banner-right-icon"
                  target="blank"
                >
                  <InstagramIcon />
                </a>
                <a
                  href={googleMapsLink}
                  className="hero-banners-banner-right-icon"
                  target="blank"
                >
                  <LocationIcon />
                </a>
              </div>

              <Switch
                className={`hero-banners-banner-right-lang-s hero-banners-banner-right-lang-s-${appLangs.find(
                  (i) => i !== defaultLang
                )}`}
                // defaultChecked
                onChange={handleChangeLang}
              />
            </div>
            <swiper-container ref={swiperRef} init="false" class="hero-slider">
              <swiper-slide class="hero-slider-slide slide-1">
                <div className="transparent">
                  <div className="container">
                    <div className="headings">
                      <h1>{translations.heroSlide1H1}</h1>
                      <h2 ref={heroH2Ref}>{translations.heroSlide1H2}</h2>
                    </div>
                    <div className="button-container">
                      <Link
                        to="our-experience"
                        smooth={true}
                        duration={scrollDuration}
                        className="hero-button-wrapper"
                      >
                        <div className="primary-btn">
                          <button>
                            <p>{translations.heroSlideButton}</p>
                          </button>
                          <p>{translations.heroSlideButton}</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <img
                  ref={heroIconRef}
                  className={`hero-icon ${!loading ? "hero-icon-inactive" : ""}
                ${preserveAnimation(heroIconRef, "hero-icon-inactive")}`}
                  src="./images/asc_rounded.png"
                  alt=""
                />
              </swiper-slide>
              <swiper-slide class="hero-slider-slide slide-2">
                <div className="slide-2-content">
                  <div className="slide-2-left">
                    <div className="slide-2-left-content">
                      <img
                        className="slide-2-left-content-logo"
                        src="./images/DVO.png"
                        alt="DVO logo"
                        loading="lazy"
                      />
                      <img
                        className="slide-2-left-content-stars-white stars"
                        src="./images/stars_white.png"
                        alt="white stars"
                        loading="lazy"
                      />
                      <h2 className="slide-2-left-content-heading first">
                        {translations.heroSlide2H1}
                      </h2>
                      <h3 className="slide-2-left-content-heading second">
                        {translations.heroSlide2H2}
                      </h3>
                      <h4 className="slide-2-left-content-heading third">
                        {translations.heroSlide2H3}
                      </h4>
                      <div className="slide-2-left-content-bottom-container">
                        <Link
                          className="button-container"
                          to="our-experience"
                          smooth={true}
                          duration={scrollDuration}
                        >
                          <div className="primary-btn">
                            <button className="hero-button">
                              <p>{translations.heroSlideButton}</p>
                            </button>
                            <p>{translations.heroSlideButton}</p>
                          </div>
                        </Link>
                        <img
                          className="slide-2-left-content-bottom-container-stars-green stars"
                          src="./images/stars_green.png"
                          alt="white stars"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="slide-2-right">
                    <div className="slide-2-right-content">
                      <img
                        src="./images/hero_03_jogi.png"
                        className="slide-2-right-content-image"
                        alt="suspension showcase"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <img
                  className="slider-stars slide-2-stars"
                  src="./images/stars_golden.png"
                  alt="golden stars"
                  loading="lazy"
                />
              </swiper-slide>
              <swiper-slide class="hero-slider-slide slide-3">
                <div className="slide-3-content-wrapper">
                  <div className="slide-3-left" />
                  <div className="slide-3-content">
                    <img
                      className="slide-3-content-image-left"
                      src="./images/hero_02_left.jpg"
                      alt="hero"
                      loading="lazy"
                    />
                    <div className="slide-3-content-image-right-container">
                      <img
                        className="slide-3-content-image-right-container-logo"
                        src="./images/hero_02_slogan.png"
                        alt="andreani logo"
                        loading="lazy"
                      />
                      <img
                        className="slide-3-content-image-right-container-image"
                        src="./images/hero_02_arrows.png"
                        alt="andreani arrows"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="slide-3-right" />
                </div>

                <img
                  className="slider-stars slide-3-stars"
                  src="./images/stars_golden.png"
                  alt="golden stars"
                  loading="lazy"
                />
              </swiper-slide>
            </swiper-container>
          </section>

          {/* SECTION OUR GOAL */}
          <section className="section our-goal">
            <div className="our-goal-content">
              <h2 className="our-goal-content-heading">
                {translations.ourGoalH2}
              </h2>
              <p className="our-goal-content-paragraph">
                {translations.ourGoalP}
              </p>

              <div className="our-goal-content-stars-golden-container second">
                <img
                  className="our-goal-content-stars-golden stars second"
                  src="./images/stars_golden.png"
                  alt="golden stars"
                  loading="lazy"
                />
              </div>
            </div>
          </section>

          {/* SECTION OUR EXPERIENCE */}
          <section className="section our-experience">
            <Parallax
              strength={300}
              bgImage={"./images/our_experience_bgr.jpg"}
            >
              <div className="our-experience-content">
                <div className="our-experience-content-container">
                  <h3 className="our-experience-content-paragraph-title">
                    {translations.ourExperienceH1}
                  </h3>
                  <p className="our-experience-content-paragraph">
                    {translations.ourExperienceP}
                  </p>
                </div>
              </div>
            </Parallax>
          </section>

          {/* SECTION OUR FOCUS */}
          <section className="section our-focus">
            <div className="our-focus-content">
              <h3 className="our-focus-content-heading">
                {translations.ourFocusH1}
              </h3>
              <img
                className="our-focus-content-logo focus-logo"
                src="./images/andreani_shadow.png"
                alt="andreani logo"
                loading="lazy"
                width="auto"
                height="100%"
              />
              <div className="our-focus-content-suspensions">
                <div
                  id="fox"
                  className="our-focus-content-suspensions-item focus-item"
                >
                  <img
                    /*className="our-focus-content-suspensions-item-image" */
                    src="./images/service-fox.png"
                    alt="fox suspension"
                    loading="lazy"
                    width="auto"
                    height="100%"
                  />

                  <div className="our-focus-content-suspensions-item-image-hover-container">
                    <img
                      className={`our-focus-content-suspensions-item-image ${
                        isTouchDevice ? "touch" : "hover"
                      }`}
                      src="./images/service-fox_h.png"
                      alt="fox suspension"
                      onTouchEnd={(e) => toggleOnClickEffect(e)}
                      loading="lazy"
                    />
                  </div>
                </div>
                <img
                  className="our-focus-content-suspensions-star focus-star"
                  src="./images/star_white.png"
                  alt="star"
                  loading="lazy"
                />
                <div
                  id="ohlins"
                  className="our-focus-content-suspensions-item focus-item"
                >
                  <img
                    className="our-focus-content-suspensions-item-image"
                    src="./images/service-ohlins.png"
                    alt="ohlins suspension"
                    loading="lazy"
                  />

                  <div className="our-focus-content-suspensions-item-image-hover-container">
                    <img
                      className={`our-focus-content-suspensions-item-image ${
                        isTouchDevice ? "touch" : "hover"
                      }`}
                      src="./images/service-ohlins_h.png"
                      alt="ohlins suspension"
                      onTouchEnd={(e) => toggleOnClickEffect(e)}
                      loading="lazy"
                    />
                  </div>
                </div>
                <img
                  className="our-focus-content-suspensions-star focus-star"
                  src="./images/star_white.png"
                  alt="star"
                  loading="lazy"
                />
                <div
                  id="rockshox"
                  className="our-focus-content-suspensions-item focus-item"
                >
                  <img
                    className="our-focus-content-suspensions-item-image"
                    src="./images/service-rockshox.png"
                    alt="rockshox suspension"
                    loading="lazy"
                  />

                  <div className="our-focus-content-suspensions-item-image-hover-container">
                    <img
                      className={`our-focus-content-suspensions-item-image ${
                        isTouchDevice ? "touch" : "hover"
                      }`}
                      src="./images/service-rockshox_h.png"
                      alt="rockshox suspension"
                      onTouchEnd={(e) => toggleOnClickEffect(e)}
                      loading="lazy"
                    />
                  </div>
                </div>
                <div
                  id="dvo"
                  className="our-focus-content-suspensions-item focus-item"
                >
                  <img
                    className="our-focus-content-suspensions-item-image"
                    src="./images/service-dvo.png"
                    alt="dvo suspension"
                    loading="lazy"
                  />
                  <div className="our-focus-content-suspensions-item-image-hover-container">
                    <img
                      className={`our-focus-content-suspensions-item-image ${
                        isTouchDevice ? "touch" : "hover"
                      }`}
                      src="./images/service-dvo_h.png"
                      alt="dvo suspension"
                      onTouchEnd={(e) => {
                        toggleOnClickEffect(e);
                      }}
                      loading="lazy"
                    />
                  </div>
                </div>
                <img
                  className="our-focus-content-suspensions-star focus-star"
                  src="./images/star_white.png"
                  alt="star"
                  loading="lazy"
                />
                <div
                  id="braking"
                  className="our-focus-content-suspensions-item focus-item"
                >
                  <img
                    className="our-focus-content-suspensions-item-image"
                    src="./images/service-braking.png"
                    alt="braking"
                    loading="lazy"
                  />

                  <div className="our-focus-content-suspensions-item-image-hover-container">
                    <img
                      className={`our-focus-content-suspensions-item-image ${
                        isTouchDevice ? "touch" : "hover"
                      }`}
                      src="./images/service-braking_h.png"
                      alt="braking"
                      onTouchEnd={(e) => toggleOnClickEffect(e)}
                      loading="lazy"
                    />
                  </div>
                </div>
                <img
                  className="our-focus-content-suspensions-star focus-star"
                  src="./images/star_white.png"
                  alt="star"
                  loading="lazy"
                />
                <div
                  id="formula"
                  className="our-focus-content-suspensions-item focus-item"
                >
                  <img
                    className="our-focus-content-suspensions-item-image"
                    src="./images/service-formula.png"
                    alt="formula suspension"
                    loading="lazy"
                  />

                  <div className="our-focus-content-suspensions-item-image-hover-container">
                    <img
                      className={`our-focus-content-suspensions-item-image ${
                        isTouchDevice ? "touch" : "hover"
                      }`}
                      src="./images/service-formula_h.png"
                      alt="formula suspension"
                      onTouchEnd={(e) => toggleOnClickEffect(e)}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <div className="our-focus-content-paragraph-container">
                <p className="our-focus-content-paragraph">
                  {translations.ourFocusP}
                </p>
              </div>
            </div>
          </section>

          {/* SECTION SERVICE */}
          <section className="section service">
            <div className="service-content">
              <div className="service-content-top">
                <div className="service-content-top-left">
                  <h3 className="service-content-top-left-heading top-heading">
                    {translations.serviceH1}
                  </h3>
                  <p className="service-content-top-left-paragraph top-paragraph">
                    {translations.serviceP1}
                    <br />
                    <br /> {translations.serviceP2}
                  </p>
                </div>
                <div className="service-content-top-right">
                  <img
                    className="service-content-top-right-image top-bike"
                    //src="./images/jogi_mid_cutout.png"
                    src="./images/jogi_cutout_initial.png"
                    alt="bike"
                  />

                  <div className="service-content-top-right-bcks">
                    <img
                      className="service-content-top-right-image-bck-blust normal"
                      src="./images/blast.jpg"
                      alt="blast"
                      loading="lazy"
                    />
                    <img
                      className="service-content-top-right-image-bck-blust rotated"
                      src="./images/blast.jpg"
                      loading="lazy"
                      alt="blast"
                    />

                    <img
                      className="service-content-top-right-image-bck normal"
                      src="./images/blast.jpg"
                      alt="blast"
                      loading="lazy"
                    />

                    <img
                      className="service-content-top-right-image-bck rotated"
                      src="./images/blast-rotated.jpg"
                      alt="blast"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <div className="service-content-squares">
                <div className="square-container setup">
                  <h3 className="heading">{translations.serviceItem1H}</h3>
                  <div className="square-content-bck"></div>
                  <div className={`square-content setup hover hover`}>
                    <img
                      src="./images/icon-setup.png"
                      alt="setup icon"
                      className="square-icon"
                      loading="lazy"
                    />
                    <div className="square-text-container">
                      <h3 className="square-heading">
                        {translations.serviceItem1H}
                      </h3>
                      <p className="square-paragraph">
                        {translations.serviceItem1P}
                      </p>
                    </div>
                    <img
                      src="./images/square-star.png"
                      alt="star"
                      className="square-star"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="square-container parts">
                  <h3 className="heading">{translations.serviceItem2H}</h3>
                  <div className="square-content-bck"></div>
                  <div className={`square-content setup hover`}>
                    <img
                      src="./images/icon-parts.png"
                      alt="parts icon"
                      className="square-icon"
                      loading="lazy"
                    />
                    <div className="square-text-container">
                      <h3 className="square-heading">
                        {translations.serviceItem2H}
                      </h3>
                      <p className="square-paragraph">
                        {translations.serviceItem2P}
                      </p>
                    </div>
                    <img
                      src="./images/square-star.png"
                      alt="star"
                      className="square-star"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="square-container custom">
                  <h3 className="heading">{translations.serviceItem3H}</h3>
                  <div className="square-content-bck"></div>
                  <div className={`square-content setup hover`}>
                    <img
                      src="./images/icon-custom.png"
                      alt="custom icon"
                      className="square-icon"
                      loading="lazy"
                    />
                    <div className="square-text-container">
                      <h3 className="square-heading">
                        {translations.serviceItem3H}
                      </h3>
                      <p className="square-paragraph">
                        {translations.serviceItem3P}
                      </p>
                    </div>
                    <img
                      src="./images/square-star.png"
                      alt="star"
                      className="square-star"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="square-container upgrades">
                  <h3 className="heading">{translations.serviceItem4H}</h3>
                  <div className="square-content-bck"></div>
                  <div className={`square-content setup hover`}>
                    <img
                      src="./images/icon-upgrades.png"
                      alt="upgrades icon"
                      className="square-icon"
                      loading="lazy"
                    />
                    <div className="square-text-container">
                      <h3 className="square-heading">
                        {translations.serviceItem4H}
                      </h3>
                      <p className="square-paragraph">
                        {translations.serviceItem4P}
                      </p>
                    </div>
                    <img
                      src="./images/square-star.png"
                      alt="star"
                      className="square-star"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="square-container professional">
                  <h3 className="heading">{translations.serviceItem5H}</h3>
                  <div className="square-content-bck"></div>
                  <div className={`square-content setup hover`}>
                    <img
                      src="./images/icon-professional.png"
                      alt="professional icon"
                      className="square-icon"
                      loading="lazy"
                    />
                    <div className="square-text-container">
                      <h3 className="square-heading">
                        {translations.serviceItem5H}
                      </h3>
                      <p className="square-paragraph">
                        {translations.serviceItem5P}
                      </p>
                    </div>
                    <img
                      src="./images/square-star.png"
                      alt="star"
                      className="square-star"
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="square-container paint">
                  <h3 className="heading">{translations.serviceItem6H}</h3>
                  <div className="square-content-bck"></div>
                  <div className={`square-content setup hover`}>
                    <img
                      src="./images/icon-paint.png"
                      alt="paint icon"
                      className="square-icon paint"
                      loading="lazy"
                    />

                    <div className="square-text-container">
                      <h3 className="square-heading">
                        {translations.serviceItem6H}
                      </h3>
                      <p className="square-paragraph">
                        {translations.serviceItem6P}
                      </p>
                    </div>
                    <img
                      src="./images/square-star.png"
                      alt="star"
                      className="square-star"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* SECTION CONTACT */}
          <section className="section contact">
            <div className="contact-content">
              <div className="contact-content-inner">
                <div className="contact-content-top">
                  <div className="contact-content-heading">
                    <h1>{translations.contactH1}</h1>
                    <img
                      src="./images/stars_golden.png"
                      alt="stars"
                      loading="lazy"
                    />
                  </div>
                  <div className="contact-content-form">
                    <div>
                      <Input
                        placeholder={translations.contactFormName}
                        value={formData.name}
                        className={
                          formState.fields.includes("name") ? "has-error" : ""
                        }
                        onChange={(e) => handleChange("name", e.target.value)}
                      />
                      <Input
                        placeholder={translations.contactFormEmailOrPhone}
                        value={formData.emailOrPhone}
                        className={
                          formState.fields.includes("emailOrPhone")
                            ? "has-error"
                            : ""
                        }
                        onChange={(e) =>
                          handleChange("emailOrPhone", e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <TextArea
                        placeholder={translations.contactFormMessage}
                        value={formData.message}
                        className={
                          formState.fields.includes("message")
                            ? "has-error"
                            : ""
                        }
                        onChange={(e) =>
                          handleChange("message", e.target.value)
                        }
                      />
                    </div>
                    {(formState.submitted || formState.error) && (
                      <div className={`notifications`}>
                        <div
                          className={`content ${
                            formState.submitted ? "success" : "error"
                          }`}
                        >
                          {formState.error ? <ErrorIcon /> : <SuccessIcon />}

                          <p className="message">{formState.message || ""}</p>
                          <svg
                            className="icon-close"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() =>
                              setFormState({
                                ...formState,
                                submitted: false,
                                error: false,
                                fields: [],
                                message: "",
                              })
                            }
                          >
                            <rect
                              width="24"
                              height="24"
                              fill="white"
                              fillOpacity="0.01"
                            />
                            <path
                              d="M12 10.586L6.707 5.293C6.51839 5.11084 6.26579 5.01005 6.00359 5.01233C5.7414 5.01461 5.49058 5.11977 5.30518 5.30518C5.11977 5.49059 5.0146 5.7414 5.01232 6.0036C5.01004 6.2658 5.11084 6.5184 5.293 6.707L10.586 12L5.293 17.293C5.11084 17.4816 5.01004 17.7342 5.01232 17.9964C5.0146 18.2586 5.11977 18.5094 5.30518 18.6948C5.49058 18.8802 5.7414 18.9854 6.00359 18.9877C6.26579 18.99 6.51839 18.8892 6.707 18.707L12 13.414L17.293 18.707C17.4816 18.8892 17.7342 18.99 17.9964 18.9877C18.2586 18.9854 18.5094 18.8802 18.6948 18.6948C18.8802 18.5094 18.9854 18.2586 18.9877 17.9964C18.9899 17.7342 18.8892 17.4816 18.707 17.293L13.414 12L18.707 6.707C18.8025 6.61475 18.8787 6.50441 18.9311 6.38241C18.9835 6.2604 19.0111 6.12918 19.0122 5.9964C19.0134 5.86362 18.9881 5.73194 18.9378 5.60905C18.8875 5.48615 18.8133 5.3745 18.7194 5.28061C18.6255 5.18671 18.5138 5.11246 18.3909 5.06218C18.2681 5.0119 18.1364 4.9866 18.0036 4.98775C17.8708 4.9889 17.7396 5.01649 17.6176 5.0689C17.4956 5.12131 17.3852 5.19749 17.293 5.293L12 10.586Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                    <div>
                      <LinksIconComponent
                        links={{
                          facebook: facebookLink,
                          instagram: instagramLink,
                          googleMaps: googleMapsLink,
                        }}
                      />
                      <button onClick={submitForm}>
                        <p>{translations.contactFormButton}</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="contact-content-bottom">
                  <img
                    className="contact-content-logo"
                    src="./images/allstars-logo-footer.svg"
                    alt="allstars logo"
                    loading="lazy"
                  />

                  <img
                    className="contact-content-jogi"
                    src="./images/contact-jogi.png"
                    alt="jogi"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </section>

          {/* SECTION FOOTER */}
          <section className="section footer">
            <div className="footer-content">
              <h1>Allstars Customs</h1>
              <div className="content">
                <div>
                  <a href="mailto:hello@allstarscustoms.com">
                    hello@allstarscustoms.com
                  </a>
                  <a href="tel:+421917433079">+421 917 433 079</a>
                </div>
                <img
                  src="./images/star_glow.png"
                  alt="start glow"
                  loading="lazy"
                />
                <div>
                  <p>Jozef Semančík, Popradská 57,</p>
                  <p>04011 Košice, Slovakia</p>
                </div>
              </div>
              <div className="icons">
                <LinksIconComponent
                  links={{
                    facebook: facebookLink,
                    instagram: instagramLink,
                    googleMaps: googleMapsLink,
                  }}
                />
              </div>
            </div>
            <a
              href="https://orka.ooo"
              className="orka-logo-wrapper"
              target="blank"
            >
              <OrkaOOOIcon />
            </a>
          </section>
        </main>
      </div>
    </React.Fragment>
  );
}

export default App;
